import React, { Component } from 'react'
import '../css/colorSet.css'

export default class Footer extends Component {
  render() {
    const currentYear = new Date().getFullYear(); // Bugünün yıl bilgisini alır
    return (
      <div>
        <footer id='footer' className="footer-set d-flex align-items-center justify-content-center text-center">
          <div className='opacity-75 footer-set-mb'>Designed by Ali Mutlu © 2022-{currentYear}</div>
        </footer>
      </div>
    )
  }
}
