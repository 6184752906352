import React, { Component } from 'react'
import '../css/main.css'
import '../css/colorSet.css'

class Navbar extends Component {
  render(props) {
    return (
  
      <header id='myheader' className='min-vw-100'>
        
        <nav id='navbar' className="navbar sticky-top">
            <div className="container container-fluid navbar-mb">
                <div className="navbar-brand">
                  <h2 className='Nav-Ali'>Ali Mutlu</h2>
                </div>

                <div className='justify-content-end'>
                    <div className='d-none d-md-block d-flex nav-set navset-a'>
                    {/*<a href="#" rel="noopener noreferrer"><i className="fa-solid fa-house"></i> Home</a>*/}
                      {/*<Link>Home</Link>
                      <Link>About Me</Link>
                      <Link>Skills</Link>
                      <Link>Contact</Link>*/}
                      <a href={'http://blog.alimutlu.net/'} target="_blank" rel="noopener noreferrer"><i className="fa-solid fa-pen-to-square"></i> Blog </a>
                    </div>
                    <div className='d-block d-md-none navset-b'>
                    {/*<a href="#" rel="noopener noreferrer"><i className="fa-solid fa-house"></i></a>*/}
                      <a href={'http://blog.alimutlu.net/'} target="_blank" rel="noopener noreferrer"><i className="fa-solid fa-pen-to-square"></i></a>
                    </div>
                </div>
            </div>
        </nav>

      </header>

    )
  }
}

export default Navbar;