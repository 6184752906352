import React, { Component } from 'react'
import '../css/settings.css'

class AboutMe extends Component {

  render() {

    return (

      <div id='aboutMe'>

        <div className='container'>

            <div className='row hope min-vh-100 justify-content-center aligin-items-center'>
            
                <div className='row'></div>

                <div className='col-md-12 text-center mx-auto about-me-pc'>

                    <h2 className='pageTittle'>About Me</h2>
                    <br/>
                    <p>
                        Hi, I'm Ali
                        <br/>
                        Let me tell you briefly about myself
                        <br/>
                        <br/>
                        I completed my primary and secondary education in Kütahya. Then I enrolled in the <em>Computer Engineering program at Süleyman Demirel University</em>. My journey, which began at a very young age, has brought me to this day. Currently, I'm a 3rd-year student in Computer Engineering.
                        <br/>
                        <br/>
                        I believe that I've done my best to improve myself and my learning. For this reason, I can define myself as a fullstack developer. In addition to the programming languages I mentioned in the "My Skills" section <em>(React, JavaScript and TypeScript, jQuery, NodeJs)</em>, there are also programming languages that I aim to specialize in <em>(Python, ReactNative)</em>. Besides all of this, I have some knowledge that allows me to understand and interpret most programming languages.
                        <br/>
                        <br/>
                        Alright, how about working together? <br/> You can reach me at my email address: <strong><a className='contact-ali' href="mailto:contact@alimutlu.net" rel="noopener noreferrer">contact@alimutlu.net</a></strong>
                        <br/>    
                    </p>

                </div>

            </div>
        
        </div>
        
      </div>

    )

  }
}

export default AboutMe;