import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';

import '../css/main.css';
import '../css/settings.css';
import '../css/colorSet.css';
import '../specialFontsCS/stylesheetone.css'

/*import Navbar from "../components/Navbar"*/
import Footer from '../components/Footer';

import HomePage from '../home_components/HomePage';
import AboutMe from '../home_components/AboutMe';
import Skills from '../home_components/Skills';
import Navbar from '../components/Navbar';

function MainPage() {

  return (
    <div>

      <Navbar />

      <HomePage id='idhome' />

      <span className='d-none'>
        <AboutMe id='idaboutme' />
      </span>

      <Skills id='idskills' />

      <Footer />

    </div>
  )
}

export default MainPage
