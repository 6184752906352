import React, { Component } from 'react'
import ProgressBar from '../components/ProgressBar';
import '../css/settings.css'
import '../css/colorSet.css'

class Skills extends Component {

  render() {

    return (

        <div id='Skills'>

            <div className='container'>
                <div className='skill-mb-set row hope min-vh-100 align-items-center justify-content-center text-center'>

                <div className='row mb-d-none'></div>
                <div className='row mb-d-none'></div>

                <h2 className='pageTittle'>My Skills</h2>

                    <div>

                        <div className='row'>

                            <div className="skill-box col-md-4 skill-react">
                                <div className="mt-1 fs-3 text-star align-items-center d-flex"><i className="fa-brands fa-react fa-2x"></i>
                                    <p className="opacity-0">_</p> React </div>
                                <div className="mt-3">
                                    <ProgressBar value={65}/>
                                </div>
                            </div>

                            <div className="skill-box col-md-4 skill-reactNative">
                                <div className="fs-3 text-star align-items-center d-flex"><i className="fa-brands fa-react fa-2x"></i>
                                    <p className="opacity-0">_</p> React Native </div>
                                <div className="mt-3">
                                    <ProgressBar value={20}/>
                                </div>
                            </div>

                            <div className="skill-box col-md-4 skill-js">
                                <div className="fs-3 text-star align-items-center d-flex"><i className="fa-brands fa-js fa-2x"></i>
                                    <p className="opacity-0">_</p> JavaScript </div>
                                <div className="mt-3">
                                    <ProgressBar value={60}/>
                                </div>
                            </div>

                        </div>

                        <div className='d-none d-md-block row mt-5'></div>

                        <div className='row'>

                            <div className="skill-box col-md-4 skill-nodejs">
                                <div className="fs-3 text-star align-items-center d-flex"><i class="fa-brands fa-node-js fa-2x"></i>
                                    <p className='opacity-0'>_</p> NodeJS</div>
                                <div className="mt-3">
                                    <ProgressBar value={45}/>
                                </div>
                            </div>

                            <div className="skill-box col-md-4 skill-python">
                                <div className="fs-3 text-star align-items-center d-flex"><i className="fa-brands fa-python fa-2x"></i>
                                    <p className="opacity-0">_</p> Python </div>
                                <div className="mt-3">
                                    <ProgressBar value={20}/>
                                </div>
                            </div>

                            <div className="skill-box col-md-4 skill-css">
                                <div className="fs-3 text-star align-items-center d-flex"><i className="fa-brands fa-css3-alt fa-2x"></i>
                                    <p className="opacity-0">_</p> CSS </div>
                                <div className="mt-3">
                                    <ProgressBar value={75}/>
                                </div>
                            </div>

                        </div>

                        <div className='row mt-5 mb-d-none'></div>
                        <div className='row mt-5 mb-d-none'></div>
                        <div className='row mt-2 mb-d-none'></div>

                        {/*
                        <div className='d-none d-md-block row mt-5'></div>

                        <div className='row'>

                            <div className="col-md-4"></div>

                            <div className="skill-box col-md-4 skill-html">
                                <div className="fs-3 text-star align-items-center d-flex"><i className="fa-brands fa-html5 fa-2x"></i>
                                    <p className="opacity-0">_</p> HTML </div>
                                <div className="mt-3">
                                    <ProgressBar value={85}/>
                                </div>
                            </div>

                            <div className="col-md-4"></div>
                        </div>
                        */}

                    </div>
                </div>
            </div>
        </div>

    )

  }

}

export default Skills;