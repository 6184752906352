import './App.css';
import React, { Component } from 'react'
import MainPage from './pages/MainPage'

class App extends Component {
  
  render() {

    return (
      <div>      

        <MainPage/>
  
      </div>
    );
    
  }
}

export default App;