import React, { Component } from 'react'
import downGif from '../images/downicons.gif'
import '../css/colorSet.css'
import SpotifyListening from '../components/SpotifyListening'

class homepage extends Component {
  render() {

    return (
      
      <div id='home'>

        <div className='container'>

            <div className='row hope min-vh-100 aligin-items-center hp'>

                <div className='row'></div>

                <div className='col-md-3 d-flex justify-content-center align-items-center inf-img'>
                    <div className='profile-set'>

                        <img src={'https://alimutlu.net/pp.jpg'} className='profile-photo' alt='MyProfilePhoto'/>
                        <div className='department'>
                            <h5 className='opacity-50'>
                                Computer Engineering
                                <p className='opacity-75'>4th Year Student</p>
                            </h5>
                        </div>
                
                    </div>
                </div>

                <div className='d-none d-md-block col-md-1'>  </div>

                <div className='col-md-8 d-flex justify-content-center align-items-center'>
                    
                    <div className='inf'>

                        <h1>Hi, I'm Ali</h1>
                        
                        <h5 className='opacity-50'>
                        Full Stack Developer
                        </h5>

                        <br/>
                        
                        <div className='opacity-75 inf-text'>
                            An adventure that started with my curiosity and love for technology from a very young age
                            <br/> As I gain experience and learn, I develop my own projects and website.
                            <br/>
                            <p></p>
                            <strong className='opacity-75'><em>Software designs the future...</em></strong>
                        </div>

                        <div className='butons-mt'>
                            <div className='d-flex justify-content-center'>

                            <a className='button' href={'https://alimutlu.net/ali_mutlu_cv.pdf'} rel="noopener noreferrer"> <i className="fa-solid fa-download"></i> CV </a>
                            <a className='button' href={'http://blog.alimutlu.net/'} rel="noopener noreferrer"> <i className="fa-solid fa-globe"></i> Blog </a>
                        
                            </div>
                        </div>

                    </div>

                </div>

                <div className="row row-set">
                    <div className="col-md-12">
                    </div>

                    <div className="col-md-12 justify-content-center">
                        <div className="social fs-2">
                            <div className="social-links"><a href="https://www.linkedin.com/in/alihappy" target="_blank" rel="noopener noreferrer" className="linkedin"><i className="fa-brands fa-linkedin"></i></a></div>
                            <div className="social-links"><a href="https://www.instagram.com/alihappy_/" target="_blank" rel="noopener noreferrer" className="instagram"><i className="fa-brands fa-instagram"></i></a></div>
                            <div className="social-links"><a href="https://twitter.com/alihepi" target="_blank" rel="noopener noreferrer" className="twetter"><i className="fa-brands fa-twitter"></i></a></div>
                            <div className="social-links"><a href="https://github.com/alihepi" target="_blank" rel="noopener noreferrer" className="github"><i className="fa-brands fa-github"></i></a></div>
                            <div className="social-links ytb"><a href="https://www.youtube.com/channel/UCjnX0OUxao8lfqMc-KLwBpg" rel="noopener noreferrer" target="_blank" className="youtube"><i className="fa-brands fa-youtube"></i></a></div>
                            {/*<div className="social-links spotify-logo"><a href="https://open.spotify.com/user/v61zjpgd5ylvpiu2c812gypnz?si=ece3a5bd7a7b417b" rel="noopener noreferrer" target="_blank" className="spotify"><i className="fa-brands fa-spotify"></i></a></div>*/}
                            <div className="social-links spotify-text"><a href="https://open.spotify.com/user/v61zjpgd5ylvpiu2c812gypnz?si=ece3a5bd7a7b417b" rel="noopener noreferrer" target="_blank" className="spotify"><SpotifyListening/></a></div>
                        </div>
                    </div>

                    <div className="col-ms-12 d-flex down-ic justify-content-center align-items-center spc-size-set-a1 dowm-imc-mb">
                        <img src={downGif} alt='downicon'/>
                    </div>

                </div>

            </div>
        
        </div>
          



      </div>
      
      )

  }
}

export default homepage;