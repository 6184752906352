// Dependencies
import React, {Component} from 'react';
import SpotifyListener from 'spotify-activity-listener';

import '../css/spotify.css';

class SpotifyListening extends Component {
  constructor(props) {
    super(props);

    this.Listener = new SpotifyListener({
      username: 'alihappy',
      key: 'a3cb525ae0d12ea09a7ff8688d53c7b8',
      trackCount: 1,
      pollInterval: 3000,
      callback: (res) => this.setState({
        track: res[0],
      }),
    });

    this.state = {
      track: {},
    };
  }

  componentDidMount() {
    this.Listener.startListening();
  }

  componentWillUnmount() {
    this.Listener.stopListening();
  }

  render() {
    return(
      <div className='spotify-main-set'>
        {(this.state.track.art) ? <img src={this.state.track.art} className='img-set' alt='SpotifyAlbumPp' /> : null }
        <div className='d-flex align-items-center justify-content-center'>
          <div className='spotify-card-set card-set'>
            <i className="fa-brands fa-spotify"></i>
          </div>
          <div className='spotify-card-set'>
            <div style={{ padding: 0, margin: 0 }} className="small spotify-name">{ this.state.track.name }</div>
            <div style={{ padding: 0, margin: 0 }} className="small spotify-artist">{ this.state.track.artist }</div>
            <div style={{ padding: 0, margin: 0 }} className="small spotify-album">{ this.state.track.album }</div>
          </div>
        </div>
      </div>
    );
  }
}   

export default SpotifyListening;